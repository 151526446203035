import { API_URL } from 'env';
import { useCallback, useEffect, useState } from 'react';
import { getHeadersAuth } from 'utils';

interface ITag {
  tagId: string;
  tagUid: string;
  linkedItemId: string;
  note: string;
  modelName: string;
  manufacturer?: string;
  features?: string[];
  memorySize?: string;
  pages?: string;
  bytesPerPage?: string;
  dataFormat?: string;
  canBeMadeReadOnly?: boolean;
  createdAt: number;
  updatedAt: number;
  hidden?: boolean;
  passwordProtected?: boolean;
  password?: string;
  scanRecords?: string[];
}

interface UseTagsOptions {
  initialTags?: ITag[];
  autoLoad?: boolean;
  cacheTimeout?: number; // milliseconds
}

interface TagError {
  message: string;
  code?: number;
}

interface UseTagsReturn {
  tags: ITag[];
  loading: boolean;
  error: TagError | null;
  refreshTags: () => Promise<void>;
  getTagById: (tagId: string) => ITag | undefined;
  getTagsByLinkedItem: (linkedItemId: string) => ITag[];
  createTag: (tag: Omit<ITag, 'tagId' | 'createdAt' | 'updatedAt'>) => Promise<ITag>;
  updateTag: (tagId: string, updates: Partial<ITag>) => Promise<ITag>;
  updateLinkedItem: (tagId: string, linkedItemId: string) => Promise<ITag>;
  deleteTag: (tagId: string) => Promise<boolean>;
  clearCache: () => void;
}

const api = {
  // API endpoints
  async fetchTags(): Promise<ITag[]> {
    const response = await fetch(API_URL + '/api/tags', {
      headers: getHeadersAuth()
    });
    if (!response.ok) throw new Error('Failed to fetch tags');
    return response.json();
  },

  async createTag(tag: Omit<ITag, 'tagId' | 'createdAt' | 'updatedAt'>): Promise<ITag> {
    const response = await fetch(API_URL + '/api/tags', {
      method: 'POST',
      headers: getHeadersAuth(),
      body: JSON.stringify(tag)
    });
    if (!response.ok) throw new Error('Failed to create tag');
    return response.json();
  },

  async updateTag(tagId: string, updates: Partial<ITag>): Promise<ITag> {
    const response = await fetch(API_URL + `/api/tags/${tagId}`, {
      method: 'PUT',
      headers: getHeadersAuth(),
      body: JSON.stringify(updates)
    });
    if (!response.ok) throw new Error('Failed to update tag');
    return response.json();
  },

  async updateLinkedItem(tagId: string, linkedItemId: string): Promise<ITag> {
    const response = await fetch(API_URL + `/api/tags/${tagId}/link`, {
      method: 'PUT',
      headers: getHeadersAuth(),
      body: JSON.stringify({ linkedItemId })
    });
    if (!response.ok) throw new Error('Failed to update linked item');
    return response.json();
  },

  async deleteTag(tagId: string): Promise<void> {
    const response = await fetch(API_URL + `/api/tags/${tagId}`, {
      method: 'DELETE',
      headers: getHeadersAuth()
    });
    if (!response.ok) throw new Error('Failed to delete tag');
  }
};

export function useTags({
  initialTags = [],
  autoLoad = true,
  cacheTimeout = 5 * 60 * 1000 // 5 minutes default
}: UseTagsOptions = {}): UseTagsReturn {
  const [tags, setTags] = useState<ITag[]>(initialTags);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<TagError | null>(null);
  const [lastFetch, setLastFetch] = useState<number | null>(null);

  // Fetch tags with cache consideration
  const refreshTags = useCallback(
    async (force: boolean = false) => {
      const now = Date.now();
      if (!force && lastFetch && now - lastFetch < cacheTimeout) {
        return; // Use cached data
      }

      setLoading(true);
      setError(null);

      try {
        const fetchedTags = await api.fetchTags();
        setTags(fetchedTags);
        setLastFetch(now);
      } catch (err) {
        setError({
          message: err instanceof Error ? err.message : 'Failed to fetch tags',
          code: 500
        });
      } finally {
        setLoading(false);
      }
    },
    [lastFetch, cacheTimeout]
  );

  // Initial load
  useEffect(() => {
    if (autoLoad) {
      refreshTags();
    }
  }, [autoLoad, refreshTags]);

  // Get a single tag by ID
  const getTagById = useCallback((tagId: string) => tags.find((tag) => tag.tagId === tagId), [tags]);

  // Get tags by linked item ID
  const getTagsByLinkedItem = useCallback((linkedItemId: string) => tags.filter((tag) => tag.linkedItemId === linkedItemId), [tags]);

  // Create a new tag
  const createTag = async (tag: Omit<ITag, 'tagId' | 'createdAt' | 'updatedAt'>) => {
    try {
      const newTag = await api.createTag(tag);
      setTags((current) => [...current, newTag]);
      return newTag;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to create tag');
      setError({ message: error.message, code: 400 });
      throw error;
    }
  };

  // Update a tag
  const updateTag = async (tagId: string, updates: Partial<ITag>) => {
    try {
      const updatedTag = await api.updateTag(tagId, updates);
      setTags((current) => current.map((tag) => (tag.tagId === tagId ? updatedTag : tag)));
      return updatedTag;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to update tag');
      setError({ message: error.message, code: 400 });
      throw error;
    }
  };

  // Update a tag's linked item
  const updateLinkedItem = async (tagId: string, linkedItemId: string) => {
    try {
      const updatedTag = await api.updateLinkedItem(tagId, linkedItemId);
      setTags((current) => current.map((tag) => (tag.tagId === tagId ? updatedTag : tag)));
      return updatedTag;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to update linked item');
      setError({ message: error.message, code: 400 });
      throw error;
    }
  };

  // Delete a tag
  const deleteTag = async (tagId: string) => {
    try {
      await api.deleteTag(tagId);
      setTags((current) => current.filter((tag) => tag.tagId !== tagId));
      return true;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to delete tag');
      setError({ message: error.message, code: 400 });
      throw error;
    }
  };

  // Clear the cache
  const clearCache = useCallback(() => {
    setLastFetch(null);
  }, []);

  return {
    tags,
    loading,
    error,
    refreshTags: () => refreshTags(true),
    getTagById,
    getTagsByLinkedItem,
    createTag,
    updateTag,
    updateLinkedItem,
    deleteTag,
    clearCache
  };
}
