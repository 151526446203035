// useQRCode.js
import { useCallback } from 'react';

/**
 * Hook for QR code download functionality
 * @param {string} elementId - The ID of the QR code SVG element
 * @param {string} fileName - The name for the downloaded file (without extension)
 * @returns {Function} Download function
 */
export const useQRCodeDownload = (elementId, fileName = 'qr-code') => {
  const downloadQRCode = useCallback(() => {
    const svg = document.getElementById(elementId);
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);

      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = fileName;
      downloadLink.href = pngFile;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  }, [elementId, fileName]);

  return downloadQRCode;
};

/**
 * Default QR code configuration
 */
export const defaultQRConfig = {
  size: 80,
  includeMargin: false,
  bgColor: 'transparent',
  fgColor: '#1a1a1a'
};

/**
 * Merges custom QR code config with defaults
 * @param {Object} customConfig - Custom configuration options
 * @returns {Object} Merged configuration
 */
export const createQRConfig = (customConfig = {}) => ({
  ...defaultQRConfig,
  ...customConfig
});
