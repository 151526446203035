import { API_URL, AUTH_DOMAIN } from 'env';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Loader.module.css';
interface AuthMessage {
  // type: 'REQUEST_TOKEN' | 'SEND_TOKEN' | 'TOKEN_NOT_FOUND' | 'TOKEN_RECEIVED';
  type: '鬼' | '龜' | '行動電源';
  data?: {
    '藍牙-冰淇淋'?: string;
    timestamp?: number;
  };
}

const Loader = () => {
  const [phase, setPhase] = useState<'initial' | 'preparing' | 'starting' | 'building' | 'intense' | 'warping' | 'finale' | 'ending' | 'stairway'>('initial');
  const [clouds, setClouds] = useState<Array<{ id: number; top: string; width: string; speed: number }>>([]);
  const [message, setMessage] = useState('');
  const [showSky, setShowSky] = useState(false);
  const [showWhiteOverlay, setShowWhiteOverlay] = useState(false);
  const [showStairway, setShowStairway] = useState(false);
  const starStreaksRef = useRef<HTMLDivElement>(null);
  const streakIntervalRef = useRef<NodeJS.Timer>();
  const intensityRef = useRef(0);
  const [showGateway, setShowGateway] = useState(false);
  const [particles, setParticles] = useState<Array<{ id: number; left: string; top: string; size: string; duration: string }>>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [sparkles, setSparkles] = useState<Array<{ id: number; x: string; y: string; delay: string }>>([]);
  const [bubbles, setBubbles] = useState<Array<{ id: number; size: string; left: string; delay: string }>>([]);

  // Initialize clouds
  useEffect(() => {
    const newClouds = Array.from({ length: 8 }, (_, i) => ({
      id: i,
      top: `${Math.random() * 55}%`,
      width: `${100 + Math.random() * 100}px`,
      speed: 15 + Math.random() * 25
    }));
    setClouds(newClouds);
  }, []);

  // Create a single star streak
  const createStarStreak = () => {
    if (!starStreaksRef.current) return;

    const streak = document.createElement('div');
    streak.className = `${styles.starStreak} ${styles.warping}`;

    // Use full 360-degree coverage with weighted randomization
    let angle;
    const randomVal = Math.random();

    if (phase === 'finale') {
      angle = Math.random() * 360;
    } else {
      if (randomVal < 0.3) {
        angle = Math.random() < 0.5 ? 0 : 180;
      } else if (randomVal < 0.6) {
        angle = Math.random() < 0.5 ? 90 : 270;
      } else {
        angle = Math.random() * 360;
      }
    }

    angle += Math.random() * 30 - 20; // ±15 degree variation

    // Random variation for individual streak thickness
    const thicknessVariation = 1.5 + Math.random(); // 0.5 to 1.5 multiplier

    // Set base height for thickness calculation
    streak.style.setProperty('--base-height', `${thicknessVariation}px`);

    // Other properties
    const brightness = 30 + Math.random() * 30;
    const width = phase === 'finale' ? 600 : 200 + 250 * intensityRef.current;

    streak.style.setProperty('--rotation', `${angle}deg`);
    streak.style.setProperty('--max-width', `${width}vmax`);
    streak.style.opacity = `${brightness / 100}`;

    if (phase === 'finale') {
      streak.classList.add(styles.finaleStreak);
    }

    starStreaksRef.current.appendChild(streak);

    setTimeout(
      () => {
        if (streak.parentNode) {
          streak.parentNode.removeChild(streak);
        }
      },
      phase === 'finale' ? 2000 : 1500
    );
  };

  // Create multiple streaks in a burst
  const createStreakBurst = () => {
    // Adjust number of streaks based on phase
    let burstCount;
    switch (phase) {
      case 'starting':
        burstCount = 6;
        break;
      case 'building':
        burstCount = 12;
        break;
      case 'intense':
        burstCount = 18;
        break;
      case 'warping':
        burstCount = 34;
        break;
      case 'finale':
        burstCount = 25;
        break;
      default:
        burstCount = 1;
    }

    for (let i = 0; i < burstCount; i++) {
      setTimeout(createStarStreak, Math.random() * 1);
    }
  };

  // // Initialize particles
  useEffect(() => {
    const newParticles = Array.from({ length: 500 }, (_, i) => ({
      id: i,
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      size: `${2 + Math.random() * 8}px`,
      duration: `${15 + Math.random() * 10}s`
    }));
    setParticles(newParticles);
  }, []);

  // Initialize sparkles
  // useEffect(() => {
  //   const newSparkles = Array.from({ length: 20 }, (_, i) => ({
  //     id: i,
  //     x: `${Math.random() * 200 - 100}px`,
  //     y: `${Math.random() * 200 - 100}px`,
  //     delay: `${Math.random() * 2}s`
  //   }));
  //   setSparkles(newSparkles);

  //   const newBubbles = Array.from({ length: 15 }, (_, i) => ({
  //     id: i,
  //     size: `${10 + Math.random() * 10}px`,
  //     left: `${Math.random() * 100}%`,
  //     delay: `${Math.random() * 100}s`
  //   }));
  //   setBubbles(newBubbles);
  // }, []);

  function launchTargetPWA(domain, path = '') {
    // Clean up the inputs
    domain = domain.replace(/^https?:\/\//, '').replace(/\/$/, '');
    path = path.startsWith('/') ? path : `/${path}`;
    path = path.replace(/\/$/, '');

    const fullUrl = `https://${domain}${path}`;
    const link = document.createElement('a');

    // Intent URL format with dynamic URL
    link.href = 'intent://' + domain + path + '#Intent;' + 'scheme=https;' + 'package=wtf.pack;' + `S.browser_fallback_url=${encodeURIComponent(fullUrl)};` + 'end';
    // link.href = fullUrl;

    // Hide the link
    link.style.display = 'none';
    document.body.appendChild(link);

    try {
      link.click();
    } catch (e) {
      console.error('Failed to launch PWA:', e);
      // Fallback to regular URL if intent fails
      // window.location.href = fullUrl;
    } finally {
      document.body.removeChild(link);
    }
  }

  const { id } = useParams<{ id: string }>();

  const sendScanRecord = async () => {
    // Send scan record to server

    await fetch(API_URL + '/' + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ scanType: 'b', scanStage: '2' })
    });

    // Wait for a while
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return;
  };

  const contemplate = async () => {
    const token = localStorage.getItem('藍牙-冰淇淋');
    if (!token) return;

    const response = await fetch('https://tag.api.pack.wtf/api/tags/' + id + '?item=true', {
      headers: {
        Authorization: `%E8%9B%8B%E7%AD%92_%E6%9D%AF%E5%AD%90%20 ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 401) {
      // Token is invalid
      console.log('❌🍦');
      localStorage.removeItem('藍牙-冰淇淋');
      // onAuth('');

      const urlEncodedCallback = encodeURIComponent(window.location.origin);
      const urlEncodedPath = encodeURIComponent(window.location.pathname);
      const urlEncodedQuery = encodeURIComponent(window.location.search);
      const callback = `${urlEncodedCallback}${urlEncodedPath}${urlEncodedQuery}`;
      const authUrlWithCallback = `${AUTH_DOMAIN}/?callback=${callback}`;
      window.location.href = authUrlWithCallback;
    }

    if (response.ok) {
      const json = await response.json();
      console.log(json);

      launchTargetPWA('pack.wtf', `/${json.item.type}/${json.item.id}`);

      // setTimeout(() => {
      //   window.location.href = `https://pack.wtf/${json.item.type}/${json.item.id}`;
      // }, 5000);

      return false;
    }
  };

  const [showBeam, setShowBeam] = useState(false);

  // useEffect(() => {
  //   const startSequence = async () => {
  //     // Start with black screen for 1 second
  //     await new Promise((resolve) => setTimeout(resolve, 1000));

  //     // Start lightspeed sequence
  //     setPhase('preparing');
  //     setMessage('Processing request...');
  //     await new Promise((resolve) => setTimeout(resolve, 1500));

  //     // Starting phase - very subtle
  //     setPhase('starting');
  //     intensityRef.current = 0.1;
  //     streakIntervalRef.current = setInterval(createStreakBurst, 500);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));

  //     intensityRef.current = 0.2;
  //     streakIntervalRef.current = setInterval(createStreakBurst, 250);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));

  //     intensityRef.current = 0.3;
  //     streakIntervalRef.current = setInterval(createStreakBurst, 190);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));

  //     intensityRef.current = 0.4;
  //     streakIntervalRef.current = setInterval(createStreakBurst, 160);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));

  //     // Building phase - getting stronger
  //     setPhase('building');
  //     intensityRef.current = 0.5;
  //     if (streakIntervalRef.current) clearInterval(streakIntervalRef.current);
  //     streakIntervalRef.current = setInterval(createStreakBurst, 150);
  //     await new Promise((resolve) => setTimeout(resolve, 1500));

  //     intensityRef.current = 0.6;
  //     streakIntervalRef.current = setInterval(createStreakBurst, 140);
  //     await new Promise((resolve) => setTimeout(resolve, 1500));

  //     intensityRef.current = 0.7;
  //     streakIntervalRef.current = setInterval(createStreakBurst, 130);
  //     await new Promise((resolve) => setTimeout(resolve, 1500));

  //     // Intense phase - ramping up
  //     setPhase('intense');
  //     intensityRef.current = 0.8;
  //     if (streakIntervalRef.current) clearInterval(streakIntervalRef.current);
  //     streakIntervalRef.current = setInterval(createStreakBurst, 100);
  //     await new Promise((resolve) => setTimeout(resolve, 2000));

  //     intensityRef.current = 0.9;
  //     if (streakIntervalRef.current) clearInterval(streakIntervalRef.current);
  //     streakIntervalRef.current = setInterval(createStreakBurst, 60);
  //     await new Promise((resolve) => setTimeout(resolve, 2000));

  //     // Full warping phase
  //     setPhase('warping');
  //     setMessage('Please hold on...');
  //     intensityRef.current = 1;
  //     if (streakIntervalRef.current) clearInterval(streakIntervalRef.current);
  //     streakIntervalRef.current = setInterval(createStreakBurst, 30);
  //     await new Promise((resolve) => setTimeout(resolve, 3000));

  //     // Finale phase with white flash
  //     setPhase('finale');
  //     await new Promise((resolve) => setTimeout(resolve, 10000));

  //     // Show white overlay
  //     setShowWhiteOverlay(true);
  //     await new Promise((resolve) => setTimeout(resolve, 100));
  //     setMessage('');
  //     // Clean up streaks
  //     if (streakIntervalRef.current) {
  //       clearInterval(streakIntervalRef.current);
  //     }

  //     // After showing sky and fading white overlay
  //     setShowSky(true);
  //     setPhase('ending');
  //     await new Promise((resolve) => setTimeout(resolve, 2500));
  //     setMessage('Hello.');
  //     setShowWhiteOverlay(false);
  //     await new Promise((resolve) => setTimeout(resolve, 3000));
  //     setMessage('Please make yourself at home.');
  //     await new Promise((resolve) => setTimeout(resolve, 3500));

  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setPhase('gateway');
  //     setMessage('...');
  //     setShowStairway(true);
  //     setShowGateway(true);
  //     // setShowDialog(true);
  //     await new Promise((resolve) => setTimeout(resolve, 10000));
  //     setShowStairway(false);
  //     setShowGateway(false);

  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setMessage('No, not up there...');

  //     await new Promise((resolve) => setTimeout(resolve, 1400));
  //     setMessage('Here.');
  //     await new Promise((resolve) => setTimeout(resolve, 2000));
  //     setMessage('You deserve this.');
  //     await new Promise((resolve) => setTimeout(resolve, 4000));
  //     setMessage('You always brighten my day.');
  //     await new Promise((resolve) => setTimeout(resolve, 6000));
  //     setMessage('So I thought I would permanently brighten yours. :)');
  //     await new Promise((resolve) => setTimeout(resolve, 8000));
  //     setMessage('...');

  //     await new Promise((resolve) => setTimeout(resolve, 3000));
  //     setShowGateway(true);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setShowBeam(true);
  //     setMessage('');
  //     await new Promise((resolve) => setTimeout(resolve, 14000));
  //     setShowBeam(false);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setMessage('Yes, it is a beam of light...');
  //     await new Promise((resolve) => setTimeout(resolve, 6000));
  //     setMessage('...and yes, I will keep you permanently trapped inside. :)');
  //     await new Promise((resolve) => setTimeout(resolve, 6000));
  //     setMessage('Now come on, it it way past your beam time.');
  //     await new Promise((resolve) => setTimeout(resolve, 5000));
  //     setMessage('Good night.');
  //     await new Promise((resolve) => setTimeout(resolve, 1400));
  //     setMessage(':D');
  //     await new Promise((resolve) => setTimeout(resolve, 2000));
  //     setMessage('');
  //     setShowBeam(true);
  //     await new Promise((resolve) => setTimeout(resolve, 2000));
  //     setMessage('');
  //   };

  //   sendScanRecord();
  //   startSequence();

  //   return () => {
  //     if (streakIntervalRef.current) {
  //       clearInterval(streakIntervalRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const startSequence = async () => {
      // Start lightspeed sequence
      // setPhase('preparing');
      setMessage('Processing request...');
      // await new Promise((resolve) => setTimeout(resolve, 100));

      // Intense phase - ramping up
      setPhase('intense');
      // intensityRef.current = 0.8;
      // if (streakIntervalRef.current) clearInterval(streakIntervalRef.current);
      // streakIntervalRef.current = setInterval(createStreakBurst, 100);
      // await new Promise((resolve) => setTimeout(resolve, 100));

      // intensityRef.current = 0.9;
      // if (streakIntervalRef.current) clearInterval(streakIntervalRef.current);
      // streakIntervalRef.current = setInterval(createStreakBurst, 60);
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      // Full warping phase
      // setPhase('warping');

      // setMessage('Please hold on...');
      intensityRef.current = 1;
      if (streakIntervalRef.current) clearInterval(streakIntervalRef.current);
      streakIntervalRef.current = setInterval(createStreakBurst, 30);
      await new Promise((resolve) => setTimeout(resolve, 1800));
      contemplate();
      // Finale phase with white flash
      await new Promise((resolve) => setTimeout(resolve, 10000));

      setPhase('finale');

      await new Promise((resolve) => setTimeout(resolve, 10000));

      // Show white overlay
      setShowWhiteOverlay(true);
      await new Promise((resolve) => setTimeout(resolve, 100));
      setMessage('');
      // Clean up streaks
      if (streakIntervalRef.current) {
        clearInterval(streakIntervalRef.current);
      }

      // After showing sky and fading white overlay
      setShowSky(true);
      setPhase('ending');
      await new Promise((resolve) => setTimeout(resolve, 2500));
      setMessage('Hello.');
      setShowWhiteOverlay(false);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setMessage('Please make yourself at home.');
      await new Promise((resolve) => setTimeout(resolve, 3500));

      await new Promise((resolve) => setTimeout(resolve, 1000));
      setPhase('gateway');
      setMessage('...');
      setShowStairway(true);
      setShowGateway(true);
      // setShowDialog(true);
      await new Promise((resolve) => setTimeout(resolve, 10000));
      setShowStairway(false);
      setShowGateway(false);

      await new Promise((resolve) => setTimeout(resolve, 1000));
      setMessage('No, not up there...');

      await new Promise((resolve) => setTimeout(resolve, 1400));
      setMessage('Here.');
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setMessage('You deserve this.');
      await new Promise((resolve) => setTimeout(resolve, 4000));
      setMessage('You always brighten my day.');
      await new Promise((resolve) => setTimeout(resolve, 6000));
      setMessage('So I thought I would permanently brighten yours. :)');
      await new Promise((resolve) => setTimeout(resolve, 8000));
      setMessage('...');

      await new Promise((resolve) => setTimeout(resolve, 3000));
      setShowGateway(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShowBeam(true);
      setMessage('');
      await new Promise((resolve) => setTimeout(resolve, 14000));
      setShowBeam(false);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setMessage('Yes, it is a beam of light...');
      await new Promise((resolve) => setTimeout(resolve, 6000));
      setMessage('...and yes, I will keep you permanently trapped inside. :)');
      await new Promise((resolve) => setTimeout(resolve, 6000));
      setMessage('Now come on, it it way past your beam time.');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setMessage('Good night.');
      await new Promise((resolve) => setTimeout(resolve, 1400));
      setMessage(':D');
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setMessage('');
      setShowBeam(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setMessage('');
    };

    sendScanRecord();
    startSequence();

    return () => {
      if (streakIntervalRef.current) {
        clearInterval(streakIntervalRef.current);
      }
    };
  }, []);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleAuthMessage = (event: MessageEvent<AuthMessage>) => {
      console.log(event);
      // Only accept messages from auth domain
      const isValidMessageOrigin = event.origin === AUTH_DOMAIN;

      if (!isValidMessageOrigin) {
        return;
      }

      const message = event.data;

      if (message.type === '龜') {
        console.log('🐢');
        localStorage.setItem('藍牙-冰淇淋', message.data?.['藍牙-冰淇淋'] || '');
      }
    };

    // Add message listener
    window.addEventListener('message', handleAuthMessage);

    // Request token when iframe loads
    const requestToken = () => {
      iframeRef.current?.contentWindow?.postMessage({ type: '鬼' }, { targetOrigin: AUTH_DOMAIN });
    };

    // Request token immediately if iframe is already loaded
    if (iframeRef.current) {
      requestToken();
    }

    return () => window.removeEventListener('message', handleAuthMessage);
  }, []);

  return (
    <div className={`${styles.container} ${styles[phase]}`}>
      <iframe
        ref={iframeRef}
        src={AUTH_DOMAIN + '/'}
        style={{ display: 'none' }}
        onLoad={() => {
          iframeRef.current?.contentWindow?.postMessage({ type: '鬼' }, { targetOrigin: AUTH_DOMAIN });
        }}
      />
      <div className={styles.scene}>
        <div className={`${styles.skyBackground} ${showSky ? styles.visible : ''}`}>
          <div className={styles.cloudLayer}>
            {clouds.map((cloud) => (
              <div
                key={cloud.id}
                className={styles.cloud}
                style={{
                  top: cloud.top,
                  width: cloud.width,
                  height: '40px',
                  animationDuration: `${cloud.speed}s`
                }}
              />
            ))}
          </div>
        </div>

        {/* Epic Gateway Elements */}

        {showGateway && (
          <div className={`${styles.gatewayContainer} ${showGateway ? styles.visible : ''}`}>
            {/* Central light beam */}
            {showBeam && (
              <div className={styles.centralBeam}>
                <div className={styles.beamCore} />
                <div className={styles.beamRays} />
              </div>
            )}

            {/* Stairway structure */}

            {showStairway && (
              <div className={styles.stairwayStructure}>
                {/* Side pillars */}
                {/* <div className={`${styles.pillar} ${styles.leftPillar}`} />
                <div className={`${styles.pillar} ${styles.rightPillar}`} /> */}

                {/* Steps */}

                {Array.from({ length: 10 }).map((_, index) => (
                  <div
                    key={index}
                    className={styles.epicStep}
                    style={
                      {
                        '--step-delay': `${index * 0.25}s`,
                        '--step-index': index
                      } as React.CSSProperties
                    }>
                    <div className={styles.stepTrail} />
                    <div className={styles.stepGlow} />
                    <div className={styles.stepSurface} />
                    <div className={styles.stepSide} />
                  </div>
                ))}
              </div>
            )}

            {/* Ornate arch */}
            {showStairway && (
              <div className={styles.archway}>
                <div className={styles.archDetail} />
                <div className={styles.archGlow} />
              </div>
            )}

            {/* Retro dialog box */}
            <div className={`${styles.dialogBox} ${showDialog ? styles.visible : ''}`}>
              <div className={styles.dialogHeader}>
                <div className={styles.dialogIcon} />
                <h3>Welcome, traveler!</h3>
              </div>
              <div className={styles.dialogContent}>
                {/* Content can be customized */}
                <div className={styles.dialogText}>
                  You've reached your destination.
                  {/* Add your custom content/images here */}
                </div>
              </div>
            </div>

            {/* Floating particles */}
            <div className={styles.particleContainer}>
              {particles.map((particle) => (
                <div
                  key={particle.id}
                  className={styles.particle}
                  style={{
                    left: particle.left,
                    top: particle.top,
                    width: particle.size,
                    height: particle.size,
                    animationDuration: particle.duration
                  }}
                />
              ))}
            </div>
          </div>
        )}

        <div className={`${styles.whiteOverlay} ${showWhiteOverlay ? styles.visible : styles.fading}`} />

        <div className={`${styles.warpPortal} ${phase === 'preparing' ? styles.growing : phase === 'warping' || phase === 'intense' ? styles.warping : phase === 'finale' ? styles.finale : ''}`} />

        <div
          ref={starStreaksRef}
          style={{ background: phase === 'ending' ? 'white' : 'radial-gradient(circle at 50% 50%, #000510 0%, #000000 100%)' }}
          className={`${styles.starfieldContainer} 
    ${!['initial', 'ending', 'gateway'].includes(phase) ? styles.visible : ''}
    ${phase === 'finale' ? styles.spinning : ''}
  `}>
          <div className={`${styles.centerFlash} ${phase === 'warping' || phase === 'finale' ? styles.active : ''}`} />
        </div>

        <div className={`${styles.message} ${message ? styles.visible : ''}`}>{message}</div>
      </div>
    </div>
  );
};

export default Loader;
