import AuthCheck from 'AuthCheck';
import Fuse from 'fuse.js';
import { useItems } from 'hooks/useItems';
import { useScanRecords, type IScanRecord } from 'hooks/useScanRecords';
import { useTags } from 'hooks/useTags';
import LoadingAnimation from 'Loading';
import { ArrowRight, Clock, Globe2, Scan, Search, SlidersHorizontal, Tag, X } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Search.css';
import './TTT.css';

const ItemsList = ({ items, tags }) => {
  const navigate = useNavigate();

  return (
    <div className="itemsGrid">
      {items.slice(0, 20).map((item) => {
        const tag = tags.find((tag) => tag.linkedItemId === item.id && !tag.hidden);
        return (
          <button key={item.id} className={`itemCard ${tag ? 'hasNfc' : ''}`} onClick={() => navigate(`/manage/item/${item.id}`)}>
            <div className="itemCardContent">
              <div className="itemIcon">
                <img src={item.primaryImage}></img>
              </div>
              <h3 className="itemName">{item.name}</h3>
              <p className="itemCategory">{item.category}</p>
              {tag ? (
                <div className="nfcBadge">
                  <Scan className="nfcIcon" />
                  <span>NFC Tagged</span>
                </div>
              ) : (
                <div className="registerPrompt">
                  <span>Register NFC</span>
                  <ArrowRight className="promptIcon" />
                </div>
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
};

const TagManager = () => {
  const [activeTab, setActiveTab] = useState('items');
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { recentItems, fetchRecentItems } = useItems();

  // Initialize useTags hook
  const { tags, loading, getTagsByLinkedItem, createTag, refreshTags } = useTags({
    autoLoad: true,
    cacheTimeout: 5 * 60 * 1000
  });

  // Load initial items
  useEffect(() => {
    fetchRecentItems();
  }, []);

  const onAuth = () => {
    if (items.length === 0) {
      fetchRecentItems();
    }
  };

  // Process items only when allItems changes
  useEffect(() => {
    if (recentItems && recentItems.length > 0 && items.length === 0) {
      setItems(recentItems);
      setIsLoading(false);
    }
  }, [recentItems]);

  const navigate = useNavigate();

  const handleSearch = ({ searchTerm, filters }) => {
    if (!searchTerm) {
      // setItems(items);
      fetchRecentItems();
      return;
    }
    if (!items || items.length < 1) {
      return;
    }
    const options = {
      keys: ['name', 'description'],
      includeScore: true
    };
    const fuse = new Fuse(items, options);

    const results = fuse.search(searchTerm);
    setItems(results.map((result) => result.item));
  };

  return (
    <div className="container">
      <AuthCheck onAuth={() => onAuth()} />

      <div className="tabWrapper">
        <button onClick={() => setActiveTab('items')} className={`tabButton ${activeTab === 'items' ? 'active' : ''}`}>
          <Tag className="tabIcon" />
          <span>Items</span>
        </button>
        <button onClick={() => setActiveTab('scans')} className={`tabButton ${activeTab === 'scans' ? 'active' : ''}`}>
          <Clock className="tabIcon" />
          <span>Recent Scans</span>
        </button>
      </div>
      {isLoading && <LoadingAnimation />}

      {!isLoading && (
        <div className="mainContent">
          {activeTab === 'items' ? (
            <>
              <ItemsSearch onSearch={handleSearch} items={items} />
              <ItemsList items={items} tags={tags} />
            </>
          ) : (
            <LiveScans />
          )}
        </div>
      )}
    </div>
  );
};

const ItemsSearch = ({ onSearch, items }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    hasNFC: false,
    category: 'all'
  });

  const searchRef = useRef(null);
  const uniqueCategories = [...new Set(items.map((item) => item.category))];

  // Handle search expand animation on mobile
  const handleSearchFocus = () => {
    setIsExpanded(true);
  };

  const handleSearchBlur = () => {
    if (!searchTerm) {
      setIsExpanded(false);
    }
  };

  // Handle search and filter changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    onSearch({
      searchTerm: e.target.value,
      filters
    });
  };

  const handleFilterChange = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    setFilters(updatedFilters);
    onSearch({
      searchTerm,
      filters: updatedFilters
    });
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilters({
      hasNFC: false,
      category: 'all'
    });
    onSearch({
      searchTerm: '',
      filters: {
        hasNFC: false,
        category: 'all'
      }
    });
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  return (
    <div className="searchContainer">
      <div className={`searchWrapper ${isExpanded ? 'expanded' : ''}`}>
        {/* Main Search Input */}
        <div className="searchInputWrapper">
          <Search className={`searchIcon ${searchTerm ? 'active' : ''}`} />
          <input ref={searchRef} type="text" className="searchInput" placeholder="Search items..." value={searchTerm} onChange={handleSearchChange} onFocus={handleSearchFocus} onBlur={handleSearchBlur} />
          {searchTerm && (
            <button className="clearButton" onClick={clearSearch} aria-label="Clear search">
              <X className="clearIcon" />
            </button>
          )}
          <button className={`filterButton ${showFilters ? 'active' : ''}`} onClick={() => setShowFilters(!showFilters)} aria-label="Toggle filters">
            <SlidersHorizontal className="filterIcon" />
          </button>
        </div>

        {/* Filters Panel */}
        {showFilters && (
          <div className="filtersPanel">
            <div className="filterSection">
              <label className="filterLabel">
                <input type="checkbox" checked={filters.hasNFC} onChange={(e) => handleFilterChange({ hasNFC: e.target.checked })} className="filterCheckbox" />
                <Tag className="filterIcon" />
                Has NFC Tag
              </label>
            </div>

            <div className="filterSection">
              <span className="filterGroupLabel">Category</span>
              <div className="categoryFilters">
                <button className={`categoryButton ${filters.category === 'all' ? 'active' : ''}`} onClick={() => handleFilterChange({ category: 'all' })}>
                  All
                </button>
                {uniqueCategories.map((category) => (
                  <button key={category} className={`categoryButton ${filters.category === category ? 'active' : ''}`} onClick={() => handleFilterChange({ category })}>
                    {category}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Active Filters Display */}
        {(filters.hasNFC || filters.category !== 'all') && (
          <div className="activeFilters">
            {filters.hasNFC && (
              <span className="filterTag">
                Has NFC
                <button onClick={() => handleFilterChange({ hasNFC: false })} className="removeFilter">
                  <X size={12} />
                </button>
              </span>
            )}
            {filters.category !== 'all' && (
              <span className="filterTag">
                {filters.category}
                <button onClick={() => handleFilterChange({ category: 'all' })} className="removeFilter">
                  <X size={12} />
                </button>
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

interface ScanWithNewFlag extends IScanRecord {
  isNew: boolean;
  animationEndTime?: number;
}

const ANIMATION_DURATION = 30000; // 30 seconds in milliseconds

const LiveScans: React.FC = () => {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [scans, setScans] = useState<ScanWithNewFlag[]>([]);
  const previousRecordsRef = useRef<Set<string>>(new Set());
  const { records, loading, error, getAllRecentScanRecords } = useScanRecords();

  // Initial load effect
  useEffect(() => {
    const loadInitialData = async () => {
      await getAllRecentScanRecords();
      setInitialLoadComplete(true);
    };
    loadInitialData();
  }, []);

  // Poll for updates
  useEffect(() => {
    // if (!initialLoadComplete) return;

    const interval = setInterval(() => {
      getAllRecentScanRecords();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Update scans when records change
  useEffect(() => {
    if (!records.length) return;

    const currentRecordIds = new Set(records.map((record) => record.tag_uid + record.timestamp));
    const currentTime = Date.now();

    setScans(
      records.map((record) => {
        const isNewScan = initialLoadComplete && !previousRecordsRef.current.has(record.tag_uid + record.timestamp);
        return {
          ...record,
          isNew: isNewScan,
          // Set animation end time only for new scans
          animationEndTime: isNewScan ? currentTime + ANIMATION_DURATION : undefined
        };
      })
    );

    previousRecordsRef.current = currentRecordIds;
  }, [records]);

  // Clear expired animations
  useEffect(() => {
    const animationCheckInterval = setInterval(() => {
      const currentTime = Date.now();
      setScans((prevScans) =>
        prevScans.map((scan) => ({
          ...scan,
          isNew: scan.isNew && scan.animationEndTime ? currentTime < scan.animationEndTime : false
        }))
      );
    }, 10000); // Check every second

    return () => clearInterval(animationCheckInterval);
  }, [records]);

  if (!initialLoadComplete) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  return (
    <div className="liveScanContainer">
      <div className="scanHeader">
        <h2>Recent Scans</h2>
        <div className="liveBadge">
          <div className="liveIndicator" />
          Live
        </div>
      </div>

      <div className="scansList">
        {scans
          .filter((s) => (s.scan_stage = '2'))
          .map((scan, i) => (
            <div key={`${scan.tag_uid}-${scan.timestamp}-${i}`} className={`scanRow ${scan.isNew ? 'newScan' : ''}`}>
              <div className="scanContent">
                <div className={`scanIcon ${scan.isNew ? 'newScan' : ''}`}>{scan.item ? <img style={{ width: '50px', height: '50px', objectFit: 'cover' }} src={scan.item.primaryImage} /> : <Scan className="icon" />}</div>
                <div className="scanDetails">
                  <span className="itemName">{scan.linked_item_name || scan.request_url}</span>
                  <span className="nfcId">{scan.tag_uid}</span>
                </div>
                <div className="scanInfo">
                  <span className="location">
                    <Globe2 className="locationIcon" />
                    {scan.ip_city || 'Unknown Location'} | {scan.ip_address || 'Unknown IP'}
                  </span>
                  <span className="device">{scan.user_agent}</span>
                  <span className="time">
                    {new Date(scan.timestamp + 'Z').toLocaleDateString()} {new Date(scan.timestamp + 'Z').toLocaleTimeString(undefined, { timeZoneName: 'short' })}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TagManager;

function formatDate(dateString) {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}
