const DEV_API_URL = 'http://localhost:43456';
const PROD_API_URL = 'https://tag.api.pack.wtf';
export const API_URL = PROD_API_URL;

const PROD_NFC_DOMAIN = 'https://tag.pack.wtf';
export const NFC_DOMAIN = PROD_NFC_DOMAIN;

const PROD_AUTH_DOMAIN = 'https://auth.pack.wtf';
const DEV_AUTH_DOMAIN = 'http://localhost:3001';
export const AUTH_DOMAIN = PROD_AUTH_DOMAIN;
