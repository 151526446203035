import ItemView from 'ItemView';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RegisterTag from 'RegisterTag';
import TagManager from 'TagManager';
import './index.css';
import Loader from './Loader';
import reportWebVitals from './reportWebVitals';

const Redirect = () => {
  useEffect(() => {
    window.location.href = '/manage/tags';
  }, []);

  return <div>Redirecting...</div>;
};

const router = createBrowserRouter([
  {
    path: '/manage/tags',
    element: <TagManager />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/manage/item/:itemId',
    element: <ItemView />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/manage/item/:itemId/tag/register',
    element: <RegisterTag />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/:id',
    element: <Loader />,
    errorElement: <div>404 Not Found</div>
  },
  {
    path: '/',
    element: <Redirect />,
    errorElement: <div>404 Not Found</div>
  }
]);

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
