import { Scan, Tag, Zap } from 'lucide-react';
import React from 'react';
import styles from './Loading.module.css';

const LoadingAnimation = () => {
  // Icons with their own unique animations and styles
  const icons = [
    { Icon: Scan, animation: 'floatAndSpin' },
    { Icon: Tag, animation: 'bounceAndGlow' },
    { Icon: Zap, animation: 'swingAndScale' }
  ];

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.tagsContainer}>
        {icons.map(({ Icon }, i) => (
          <div key={i} className={styles.tagWrapper}>
            <div className={styles.tag}>
              <Icon className={styles.tagIcon} />
              <div className={styles.ripple} />
              <div className={styles.sparkleTop} />
              <div className={styles.sparkleBottom} />
              <div className={styles.sparkleMiddle} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.loadingText}>
        {['L', 'o', 'a', 'd', 'i', 'n', 'g', '.', '.', '.'].map((letter, i) => (
          <span key={i} className={styles.letter}>
            {letter}
          </span>
        ))}
      </div>
    </div>
  );
};

export default LoadingAnimation;
