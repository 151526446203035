import AuthCheck from 'AuthCheck';
import { NFC_DOMAIN } from 'env';
import { useItems } from 'hooks/useItems';
import { createQRConfig, useQRCodeDownload } from 'hooks/useQRCode';
import { useTags } from 'hooks/useTags';
import LoadingAnimation from 'Loading';
import { AlertCircle, ArrowLeft, CheckCircle2, ChevronDown, HelpCircle, LucideDownload, PartyPopper, PenLine, Scan, Shield, Sparkle, Sparkles, Star, X } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './ActivityLog.module.css';
import './RegisterTag.css';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const handleRegisterNFC = async (item, tagData) => {
  try {
    await createTag({
      tagId: '',
      linkedItemId: item.id, // No need to convert to string since it already is
      note: tagData.note || '',
      modelName: tagData.modelName || 'ntag215'
    });

    refreshTags();
    await sleep(1000);
    // navigate('/tags');
  } catch (err) {
    console.error('Failed to register NFC tag:', err);
  }
};

const part1 = async () => {};

const RegisterTag = () => {
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [scanningState, setScanningState] = useState('idle'); // idle, scanning, writing, success, error
  const [note, setNote] = useState('');
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // New state for success overlay
  const [logs, setLogs] = useState<string[]>([]);
  const { createTag, refreshTags, updateTag } = useTags();

  const [isLogLoading, setIsLogLoading] = useState(false);
  const [newLogId, setNewLogId] = useState<number | null>(null);

  const addLog = (message: string) => {
    setIsLogLoading(true);
    const logId = Date.now();
    setNewLogId(logId);

    setLogs((prevLogs) => [...prevLogs, message]);

    // Keep loading state visible for a moment to show animation
    setTimeout(() => {
      setIsLogLoading(false);
      // Clear new log highlight after animation
      setTimeout(() => {
        setNewLogId(null);
      }, 1000);
    }, 1500);
  };

  const { itemId } = useParams();
  const { fetchItemById, currentItem } = useItems();

  useEffect(() => {
    fetchItemById(itemId);
  }, [itemId]);

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (currentItem) {
      setSelectedItem(currentItem);
      setLoading(false);
    }
  }, [currentItem]);

  // Simulated NFC registration process
  const handleStartScan = async () => {
    setScanningState('scanning');

    try {
      // // Simulate scanning process
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      // setScanningState('writing');
      // await new Promise((resolve) => setTimeout(resolve, 1500));
      // setScanningState('success');
      // setCurrentStep(3);
    } catch (error) {
      setScanningState('error');
    }
  };
  const [selectedModel, setSelectedModel] = useState(NFC_TAG_MODELS[0]);
  const navigate = useNavigate();

  const [newTagId, setNewTagId] = useState(null);

  const handleCompleteRegistration = async () => {
    // Show success overlay
    setShowSuccess(true);

    // Wait for success animation to complete
    await new Promise((resolve) => setTimeout(resolve, 3000));

    // Close the registration flow and pass data back
    if (newTagId && note) {
      await updateTag(newTagId, { note });
    }

    sleep(1000);

    navigate('/manage/item/' + itemId);
  };

  // Handle success overlay completion
  const handleSuccessComplete = () => {
    setShowSuccess(false);
    //navigate('/item/id');
  };

  // Auto-advance first step
  useEffect(() => {
    if (currentStep === 0) {
      const timer = setTimeout(() => setCurrentStep(2), 1000);
      return () => clearTimeout(timer);
    }
  }, [currentStep]);

  // Reset state when closing
  const handleClose = () => {
    setScanningState('idle');
    setCurrentStep(0);
    navigate('/manage/item/' + itemId);
  };

  let reader: any;

  // check if NDEFReader is supported in current browser
  if ('NDEFReader' in window) {
    reader = new (window as any).NDEFReader();
  } else {
    console.log('Web NFC is not supported.');
  }

  const abort = new AbortController();

  useEffect(() => {
    addLog('useEffect');
    if (scanningState === 'scanning' && reader) {
      addLog('scanning');

      const TIMEOUT_DURATION = 60000; // 30 seconds

      const scan = async () => {
        try {
          addLog('scan');
          // Request raw NFCReader access to get more tag details
          // @ts-ignore

          // Set timeout
          // const timeoutId = setTimeout(() => {
          //   controller.abort();
          //   addLog('Scan timeout - no tag detected for 30 seconds');
          // }, TIMEOUT_DURATION);

          await reader.scan({ signal: abort.signal });
          addLog('scan done');

          reader.addEventListener('readingerror', (error) => {
            console.error('Reading error:', error);
            addLog(`Reading error: ${error}`);
            setScanningState('error');
          });

          reader.addEventListener('reading', async ({ message, serialNumber, type }) => {
            addLog('reading event triggered');
            addLog('sending tag create request');
            const tag = await createTag({
              tagUid: serialNumber,
              linkedItemId: itemId,
              note: note,
              modelName: selectedModel.modelName
            });

            // Clear timeout since we detected a tag
            // clearTimeout(timeoutId);

            addLog('UI STEP 1');

            sleep(500);

            addLog('UI STEP 2');

            // Basic tag information
            console.log('Serial Number:', serialNumber);
            console.log('Tag Type:', type);
            addLog(`Serial Number: ${serialNumber}`);
            addLog(`Tag Type: ${type}`);
            setScanningState('writing');

            try {
              // create tag

              addLog(`Tag Created: ${tag.tagId}`);

              // Write to tag
              await sleep(1500);

              const write = await reader.write({
                records: [
                  {
                    recordType: 'url',
                    data: NFC_DOMAIN + `/${tag.tagId}`
                  }
                ]
              });

              addLog(`Tag Written: ${tag.tagId} - ${NFC_DOMAIN + `/${tag.tagId}`}`);
              setNewTagId(tag.tagId);
              setScanningState('success');
              setCurrentStep(3);
            } catch (error) {
              addLog(`Error processing tag: ${error.message}`);
              console.error('Tag processing error:', error);
              setScanningState('error');
            }
          });
        } catch (error) {
          if (error.name === 'AbortError') {
            addLog('Scanning aborted - timeout reached');
            console.log('Scanning aborted - timeout reached');
            setScanningState('error');
          } else {
            console.error('Scan error:', error);
            addLog(`Scan error: ${JSON.stringify(error)}`);
            setScanningState('error');
          }
        }
      };

      scan();
    }
  }, [scanningState]);

  useEffect(() => {
    const logContainer = document.querySelector(`.${styles.logContainer} ul`);
    if (logContainer) {
      logContainer.scrollTop = logContainer.scrollHeight;
    }
  }, [logs]);

  const onAuth = () => {
    console.log('Auth check passed');
  };

  const [qrCodeMode, setQrCodeMode] = useState(false);

  const [qrURL, setQRURL] = useState(null);
  const [qrTagId, setQrTagId] = useState(null);

  const genQr = async () => {
    const tag = await createTag({
      tagUid: Date.now().toString(),
      linkedItemId: itemId,
      note: note,
      modelName: selectedModel.modelName
    });

    setQRURL(NFC_DOMAIN + `/${tag.tagId}`);
    setQrTagId(tag.tagId);
  };

  useEffect(() => {
    if (selectedModel.id === 'qr') {
      setQrCodeMode(true);
      genQr();
    } else {
      setQrCodeMode(false);
    }
  }, [selectedModel]);

  const qrConfig = createQRConfig();
  const downloadQRCode = useQRCodeDownload('tag-qr-code', 'tag-qr-code-' + qrTagId);

  return (
    <div className="registrationFlow">
      <AuthCheck onAuth={onAuth} />
      {!loading && (
        <>
          {' '}
          {/* Success Overlay */}
          {showSuccess && <SuccessOverlay onComplete={handleSuccessComplete} />}
          {/* Header */}
          <div className="registerTagHeader">
            <button className="backButton" onClick={handleClose}>
              <ArrowLeft className="backIcon" />
            </button>
            <h2></h2>
            <button className="closeButton" onClick={handleClose}>
              <X className="closeIcon" />
            </button>
          </div>
          {/* Item Preview */}
          <div className="registerTagItemPreview">
            <div className={`previewIcon ${scanningState === 'scanning' ? 'scanning' : ''}`}>
              <img src={selectedItem.primaryImage} alt={selectedItem.name} />
            </div>
            <h3>{selectedItem.name}</h3>
            <p>{selectedItem.category}</p>
          </div>
          {/* Steps List */}
          <div className="stepsList">
            {/* Step 1: Preparation */}
            {/* <div className={`step ${currentStep === 0 ? 'active' : currentStep > 0 ? 'completed' : ''}`}>
              <div className="stepNumber">{currentStep > 0 ? <CheckCircle2 className="stepCheck" /> : '1'}</div>
              <div className="stepContent">
                <h4>Prepare NFC Tag</h4>
                <p>Get your blank NFC tag ready</p>
              </div>
            </div> */}

            {/* Step 1: Preparation */}
            <div className={`step ${currentStep === 1 ? 'active' : currentStep > 1 ? 'completed' : ''}`}>
              <div className="stepNumber">{currentStep > 1 ? <CheckCircle2 className="stepCheck" /> : '1'}</div>
              <div className="stepContent">
                <TagModelSelector
                  selectedModel={selectedModel}
                  onSelectModel={(model) => {
                    setSelectedModel(model);
                    setCurrentStep(2);
                  }}
                  setStep={setCurrentStep}
                />
              </div>
            </div>

            {/* Step 2: Scanning */}

            {!qrCodeMode ? (
              <div className={`step ${currentStep === 2 ? 'active' : currentStep > 2 ? 'completed' : ''}`}>
                <div className="stepNumber">{currentStep > 3 ? <CheckCircle2 className="stepCheck" /> : '2'}</div>
                <div className="stepContent">
                  <h4>Scan & Write Tag</h4>
                  <p>Hold the NFC tag near your device</p>
                  {currentStep === 2 && (
                    <div className="scanningStatus">
                      {scanningState === 'idle' && (
                        <button className="scanButton" onClick={handleStartScan}>
                          <Scan className="scanIcon" />
                          Start Scanning
                          <div className="buttonSparkles">
                            {[...Array(4)].map((_, i) => (
                              <div
                                key={i}
                                className="sparkle"
                                style={{
                                  '--delay': `${i * 0.2}s`
                                }}
                              />
                            ))}
                          </div>
                        </button>
                      )}
                      {scanningState === 'scanning' && (
                        <div className="statusMessage scanning">
                          <div className="scanningRing" />
                          <span>Searching for NFC tag...</span>
                        </div>
                      )}
                      {scanningState === 'writing' && (
                        <div className="statusMessage writing">
                          <div className="writingProgress" />
                          <span>Writing to tag...</span>
                        </div>
                      )}
                      {scanningState === 'error' && (
                        <div className="statusMessage error">
                          <AlertCircle className="errorIcon" />
                          <span>Error reading tag. Please try again.</span>
                          <button className="retryButton" onClick={handleStartScan}>
                            Retry
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={`step ${currentStep === 2 ? 'active' : currentStep > 2 ? 'completed' : ''}`}>
                <div className="stepNumber">{currentStep > 3 ? <CheckCircle2 className="stepCheck" /> : '2'}</div>
                <div className="stepContent">
                  <h4>QR Code</h4>

                  {qrURL ? (
                    <div style={{ width: '80px', height: '80px' }}>
                      <QRCodeSVG id="tag-qr-code" value={qrURL} {...qrConfig} />
                    </div>
                  ) : (
                    <LoadingAnimation />
                  )}
                  <button
                    className="scanButton"
                    style={{ padding: '0.2rem', marginTop: '0.1rem' }}
                    onClick={() => {
                      downloadQRCode();
                      setCurrentStep(3);
                    }}>
                    <LucideDownload className="scanIcon" />
                    Download
                    <div className="buttonSparkles">
                      {[...Array(4)].map((_, i) => (
                        <div
                          key={i}
                          className="sparkle"
                          style={{
                            '--delay': `${i * 0.2}s`
                          }}
                        />
                      ))}
                    </div>
                  </button>
                </div>
              </div>
            )}

            {/* Step 3: Add Note */}
            <div className={`step ${currentStep === 3 ? 'active' : ''}`}>
              <div className="stepNumber">{currentStep > 4 ? <CheckCircle2 className="stepCheck" /> : '3'}</div>
              <div className="stepContent">
                <h4>Add Note (Optional)</h4>
                <p>Add a note about this tag</p>
                {currentStep === 3 && (
                  <div className="noteSection">
                    {!showNoteInput ? (
                      <button className="addNoteButton" onClick={() => setShowNoteInput(true)}>
                        <PenLine className="noteIcon" />
                        Add Note
                      </button>
                    ) : (
                      <div className="noteInput">
                        <textarea value={note} onChange={(e) => setNote(e.target.value)} placeholder="E.g., Front pocket, waterproof tag..." className="noteTextarea" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Final Action */}
          {currentStep === 3 && (
            <div className="finalAction">
              <button className="completeButton" onClick={handleCompleteRegistration}>
                <CheckCircle2 className="completeIcon" />
                Complete Registration
              </button>
            </div>
          )}
        </>
      )}

      {loading && <LoadingAnimation />}

      <div className={`${styles.logContainer} ${isLogLoading ? styles.isLoading : ''}`}>
        <h2>
          <Sparkles size={2} className={styles.logIcon} />
          Activity Log
        </h2>
        {isLogLoading && (
          <div className={styles.loadingSparkles}>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
            <div className={styles.loadingSparkle}></div>
          </div>
        )}
        <ul>
          {logs.map((log, index) => (
            <li key={index} className={`${styles.logEntry} ${index === logs.length - 1 && newLogId ? styles.isNew : ''}`}>
              {log}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SuccessOverlay = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(() => onComplete(), 3000);
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="successOverlay">
      <div className="successContent">
        {/* Confetti elements */}
        {Array.from({ length: 20 }).map((_, i) => (
          <div
            key={i}
            className="confetti"
            style={{
              '--delay': `${i * 0.1}s`,
              '--angle': `${Math.random() * 360}deg`,
              '--distance': `${Math.random() * 150 + 50}px`
            }}
          />
        ))}

        {/* Stars */}
        {Array.from({ length: 8 }).map((_, i) => (
          <div
            key={i}
            className="star"
            style={{
              '--delay': `${i * 0.15}s`,
              '--size': `${Math.random() * 20 + 10}px`,
              '--x': `${Math.random() * 200 - 100}px`,
              '--y': `${Math.random() * 200 - 100}px`
            }}>
            <Star />
          </div>
        ))}

        {/* Central success animation */}
        <div className="successCircle">
          <div className="successInner">
            <CheckCircle2 className="successIcon" />
          </div>
          {/* Orbiting sparkles */}
          {Array.from({ length: 4 }).map((_, i) => (
            <div key={i} className="orbitingSparkle" style={{ '--index': i }}>
              <Sparkle />
            </div>
          ))}
        </div>

        <div className="successMessage">
          <h3 className="bounceText">
            {'Tag Registered!'.split('').map((char, i) => (
              <span key={i} style={{ '--delay': `${i * 0.05}s` }}>
                {char}
              </span>
            ))}
          </h3>
          <div className="celebrationIcons">
            <PartyPopper className="partyIcon" />
            <Sparkle className="sparkleIcon" />
            <PartyPopper className="partyIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Predefined NFC Tag Models Data
export const NFC_TAG_MODELS = [
  {
    id: 'ntag213',
    modelName: 'NTAG 213',
    manufacturer: 'NXP',
    memorySize: '144 bytes',
    frequency: '13.56 MHz',
    features: ['ISO 14443A', 'NFC Forum Type 2'],
    recommendedUse: 'Basic item tracking, small data storage',
    icon: '📱'
  },
  {
    id: 'ntag215',
    modelName: 'NTAG 215',
    manufacturer: 'NXP',
    memorySize: '504 bytes',
    frequency: '13.56 MHz',
    features: ['ISO 14443A', 'NFC Forum Type 2', 'Password Protection'],
    recommendedUse: 'Product authentication, larger data storage',
    icon: '🔐'
  },
  {
    id: 'ntag216',
    modelName: 'NTAG 216',
    manufacturer: 'NXP',
    memorySize: '888 bytes',
    frequency: '13.56 MHz',
    features: ['ISO 14443A', 'NFC Forum Type 2', 'Password Protection', 'Fast Read'],
    recommendedUse: 'Advanced applications, large data storage',
    icon: '⚡'
  },
  {
    id: 'qr',
    modelName: 'QR Code',
    manufacturer: 'QR Code',
    memorySize: 'Unlimited',
    frequency: 'Visual',
    features: ['High Capacity', 'Customizable'],
    recommendedUse: 'Dynamic data, visual scanning',
    icon: '🤳🏻'
  },
  {
    id: 'ultralight',
    modelName: 'MIFARE Ultralight',
    manufacturer: 'NXP',
    memorySize: '64 bytes',
    frequency: '13.56 MHz',
    features: ['ISO 14443A', 'NFC Forum Type 2', 'Cost-effective'],
    recommendedUse: 'Basic tracking, disposable tags',
    icon: '💡'
  }
];

// Tag Model Selector Component
const TagModelSelector = ({ selectedModel, onSelectModel, setStep }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(null);

  return (
    <div className="tagModelSection">
      <h3>Select NFC Tag Model</h3>

      <div className="tagModelSelector">
        <button
          className="selectorButton"
          onClick={() => {
            setIsOpen(!isOpen);
            setStep(1);
          }}>
          {selectedModel ? (
            <div className="selectedModel">
              <span className="modelIcon">{selectedModel.icon}</span>
              <span className="modelName">{selectedModel.modelName}</span>
              <span className="modelMemory">{selectedModel.memorySize}</span>
            </div>
          ) : (
            <span className="placeholderText">Choose a tag model</span>
          )}
          <ChevronDown className={`dropdownIcon ${isOpen ? 'open' : ''}`} />
        </button>

        {isOpen && (
          <div className="modelDropdown">
            {NFC_TAG_MODELS.map((model) => (
              <button
                key={model.id}
                className="modelOption"
                onClick={() => {
                  onSelectModel(model);
                  setIsOpen(false);
                }}
                onMouseEnter={() => setShowInfo(model.id)}
                onMouseLeave={() => setShowInfo(null)}>
                <span className="modelIcon">{model.icon}</span>
                <div className="modelInfo">
                  <span className="modelName">{model.modelName}</span>
                  <span className="modelDetails">
                    {model.memorySize} • {model.manufacturer}
                  </span>
                </div>
                <HelpCircle className="infoIcon" onMouseEnter={() => setShowInfo(model.id)} />

                {showInfo === model.id && (
                  <div className="modelTooltip">
                    <h4>{model.modelName}</h4>
                    <ul>
                      {model.features.map((feature) => (
                        <li key={feature}>
                          <Shield className="featureIcon" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <p>{model.recommendedUse}</p>
                  </div>
                )}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterTag;
