import AuthCheck from 'AuthCheck';
import { useItems } from 'hooks/useItems';
import { useTags } from 'hooks/useTags';
import LoadingAnimation from 'Loading';
import { ArrowLeft, BarChart, Clock, ComputerIcon, Globe2, Info, NfcIcon, QrCode, Shield, Tag, Users, Zap } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NFC_TAG_MODELS } from 'RegisterTag';
import './ItemView.css';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ItemView = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const { currentItem, fetchItemById } = useItems();
  const { itemId } = useParams();

  const getItem = async () => {
    try {
      await fetchItemById(itemId);
    } catch (err) {
      console.error('Failed to fetch item:', err);
    }
  };

  // Load initial item
  useEffect(() => {
    getItem();
  }, []);

  const onAuth = () => {
    if (!selectedItem) {
      getItem();
    }
  };

  useEffect(() => {
    if (currentItem) {
      console.log('Current item:', currentItem);
      setSelectedItem(currentItem);
    }
  }, [currentItem]);

  const navigate = useNavigate();

  const activeTab = 'items';

  const setActiveTab = (tab) => {
    navigate(tab === 'items' ? '/manage/tags' : '/manage/scans');
  };

  return (
    <div className="container">
      <AuthCheck onAuth={() => onAuth()} />
      <div className="tabWrapper">
        <button onClick={() => setActiveTab('items')} className={`tabButton ${activeTab === 'items' ? 'active' : ''}`}>
          <Tag className="tabIcon" />
          <span>Items</span>
        </button>
        <button onClick={() => setActiveTab('scans')} className={`tabButton ${activeTab === 'scans' ? 'active' : ''}`}>
          <Clock className="tabIcon" />
          <span>Recent Scans</span>
        </button>
      </div>
      {selectedItem ? <ItemDetails item={selectedItem} /> : <LoadingAnimation />}
    </div>
  );
};

// Item Details Component
const ItemDetails = ({ item }) => {
  const { refreshTags, updateTag, clearCache } = useTags({
    autoLoad: true,
    cacheTimeout: 5 * 60 * 1000
  });
  const [itemTags, setItemTags] = useState(null);

  const { fetchTagsByItemId, totalScans, enhancedTags } = useItems();

  // uuopdate itemtags when enhanced tags change
  useEffect(() => {
    console.log('Enhanced tags:', enhancedTags);
    if (enhancedTags && item.id) {
      setItemTags(enhancedTags[item.id]);
    }
  }, [enhancedTags]);

  const fetchTags = async () => {
    try {
      await fetchTagsByItemId(item.id);
    } catch (err) {
      console.error('Failed to fetch tags:', err);
    }
  };

  useEffect(() => {
    if (item.id) {
      fetchTags();
    }
  }, [item]);

  // useEffect(() => {
  //   if (enhancedTags) {
  //     setItemTags(enhancedTags[item.id]);
  //   }
  // }, [enhancedTags]);

  // Mock data for demonstration
  const nfcTags = [
    {
      id: 1,
      tagId: 'A1B2C3D4',
      location: 'Front Pocket',
      totalScans: 156,
      lastScanned: '2024-11-24T15:30:00',
      note: 'Waterproof tag',
      scanHistory: [
        {
          timestamp: '2024-11-24T15:30:00',
          location: 'Home',
          device: 'iPhone 13'
        },
        {
          timestamp: '2024-11-23T12:15:00',
          location: 'Office',
          device: 'Pixel 6'
        }
      ]
    },
    {
      id: 2,
      tagId: 'E5F6G7H8',
      location: 'Main Compartment',
      totalScans: 89,
      lastScanned: '2024-11-23T09:15:00',
      note: 'Backup tag',
      scanHistory: [
        {
          timestamp: '2024-11-23T09:15:00',
          location: 'Airport',
          device: 'Samsung S21'
        }
      ]
    }
  ];
  const [selectedTag, setSelectedTag] = useState(nfcTags[0]);

  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/manage/tags');
  };

  const unassignTag = async (tag) => {
    try {
      await updateTag(tag.tagId, { hidden: true });
      clearCache();
      await refreshTags();
    } catch (err) {
      console.error('Failed to unassign tag:', err);
    }
  };

  return (
    <div className="itemDetailsContainer">
      {/* Header */}
      <div className="itemDetailsHeaderTitle">
        <button className="backButton" onClick={handleClose}>
          <ArrowLeft className="backIcon" />
        </button>
        <h2></h2>
        <div className="itemBasicInfo">
          <div className="itemIcon">
            <img src={item.primaryImage} style={{ width: '1.2rem', height: '1.2rem' }} alt={item.name} />
          </div>
          <div className="itemText">
            <h2>{item.name}</h2>
            <p className="itemCategory">{item.category}</p>
          </div>
        </div>
      </div>
      {/* Item Header */}
      <div className="itemHeader">
        <button className="categoryButton" onClick={() => navigate(`/manage/item/${item.id}/tag/register`)}>
          New tag
        </button>
        <div className="itemStats">
          <div className="statCard">
            <BarChart className="statIcon" />
            <div className="statText">
              <span className="statValue">{itemTags?.filter((i) => !i.hidden).length}</span>
              <span className="statLabel">Tag{itemTags?.filter((i) => !i.hidden).length !== 1 ? 's' : ''}</span>
            </div>
          </div>
          <div className="statCard">
            <Users className="statIcon" />
            <div className="statText">
              <span className="statValue">{totalScans}</span>
              <span className="statLabel">Total Scans</span>
            </div>
          </div>
        </div>
      </div>

      {/* NFC Tags Section */}
      <div className="tagsSection">
        <h3>NFC Tags</h3>
        <div className="tagsList">
          {enhancedTags &&
            enhancedTags.length > 0 &&
            enhancedTags.map((tag) => {
              if (tag.hidden) return null;
              return <TagDetailsCard tag={tag} key={tag.tagId} setSelectedTag={setSelectedTag} unassignTag={() => unassignTag(tag)} />;
            })}
        </div>
      </div>
    </div>
  );
};

const TagDetailsCard = ({ tag, setSelectedTag, unassignTag }) => {
  const tagModel = NFC_TAG_MODELS.find((model) => model.modelName === tag.modelName);

  console.log(tag);

  // Filter and sort recent scans - only stage 2
  const recentScans = (tag.scanRecords || [])
    .filter((record) => record.scan_stage === '2')
    .sort((a, b) => b.timestamp - a.timestamp)
    .slice(0, 3);

  console.log('Recent scans:', recentScans);

  return (
    <div className="tagDetailsCard" onClick={() => setSelectedTag(tag.tagId)}>
      <div className="tagHeader">
        <div className="tagBasicInfo">
          <span className="modelIcon">{tagModel.id === 'qr' ? <QrCode /> : <NfcIcon />}</span>
          <div className="tagTitles">
            <h3 style={{ fontSize: '16px', margin: 0, marginBottom: '4px', fontFamily: 'GeistMono Regular', letterSpacing: '1px' }}>{tag.tagId}</h3>
            <span className="modelName" style={{ fontSize: '12px', opacity: 0.8 }}>
              {new Date(tag.updatedAt).toLocaleTimeString('en-US') + ' ' + new Date(tag.updatedAt).toLocaleDateString('en-US')} ・{tag.modelName}
            </span>
          </div>
        </div>

        {tag.note && (
          <div className="tagNote">
            <Info className="noteIcon" />
            {tag.note}
          </div>
        )}
      </div>

      <div className="tagSpecs">
        <div className="specItem">
          <Zap className="specIcon" />
          <div className="specContent">
            <span className="specLabel">Memory</span>
            <span className="specValue">{tagModel?.memorySize}</span>
          </div>
        </div>

        <div className="specItem">
          <Shield className="specIcon" />
          <div className="specContent">
            <span className="specLabel">UID</span>
            <span className="specValue" style={{ textTransform: 'uppercase', fontFamily: 'GeistMono Regular' }}>
              {tag.tagUid}
            </span>
          </div>
        </div>

        <div className="specItem">
          <Tag className="specIcon" />
          <div className="specContent">
            <span className="specLabel">Manufacturer</span>
            <span className="specValue">{tagModel?.manufacturer}</span>
          </div>
        </div>
      </div>

      {/* Recent Scans Section */}
      {recentScans.length > 0 && (
        <div className="tagSpecs" style={{ marginTop: '0.5rem', display: 'block', gap: '0.6rem' }}>
          <div className="specItem" style={{ gridColumn: '1 / -1' }}>
            <Clock className="specIcon" />
            <div className="specContent">
              <span className="specLabel">Recent Stage 2 Scans</span>
            </div>
          </div>
          {recentScans.map((scan, index) => (
            <div className="specItem" style={{ display: 'block', marginTop: '8px', marginBottom: '8px', background: 'rgba(59, 130, 246, 0.05)', padding: '0.1rem 0.4rem', borderRadius: '0.5rem', border: '1.2px dashed lightgrey' }}>
              <div key={scan.timestamp} className="specItem" style={{ marginTop: '8px', marginBottom: '0px', background: 'transparent', padding: '0.1rem 0.4rem', borderRadius: '0.5rem' }}>
                <Globe2 className="specIcon" style={{ width: '1rem', height: '1rem' }} />
                <div className="specContent">
                  <span className="specValue" style={{ fontSize: '12px' }}>
                    {scan.ip_city || scan.ip_location || 'Unknown Location'}
                  </span>
                  <span className="specLabel" style={{ fontSize: '8px' }}>
                    {new Date(scan.timestamp + 'Z').toLocaleString(undefined, { timeZoneName: 'short' })}
                  </span>
                </div>
              </div>

              <div key={scan.timestamp} className="specItem" style={{ marginTop: '0px', marginBottom: '8px', background: 'transparent', padding: '0.1rem 0.4rem', borderRadius: '0.5rem' }}>
                <ComputerIcon className="specIcon" style={{ width: '1rem', height: '1rem' }} />
                <div className="specContent" style={{ gridColumn: '2 / -1', marginTop: '0.5rem' }}>
                  <span className="specValue" style={{ fontSize: '0.75rem', fontFamily: 'GeistMono Regular' }}>
                    {scan.ip_address || 'Unknown IP'}
                  </span>

                  <span className="specLabel" style={{ fontSize: '6px' }}>
                    {scan.user_agent || 'Unknown Country'}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="tagActions">
        <button className="categoryButton" onClick={unassignTag}>
          Unassign
        </button>
        <button className="categoryButton">Update</button>
      </div>

      <div className="tagFeatures">
        {tagModel?.features.map((feature) => (
          <span key={feature} className="featureTag">
            {feature}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ItemView;

function formatDate(dateString) {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}
