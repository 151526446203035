import { API_URL } from 'env';
import { useState } from 'react';
import { getHeadersAuth } from 'utils';

// Define types for the API responses
interface Thing {
  id: string;
  // Add other thing properties here
}

interface Bag {
  id: string;
  // Add other bag properties here
}

interface AllItemsResponse {
  things: Thing[];
  bags: Bag[];
}

interface SingleItemResponse {
  thing: Thing | null;
  bag: Bag | null;
}

interface UseItemsReturn {
  // All items state and loading
  allItems: AllItemsResponse | null;
  // array of bags or things
  recentItems: Bag[] | Thing[];
  isLoadingAll: boolean;
  errorAll: Error | null;
  fetchAllItems: () => Promise<void>;
  fetchRecentItems: () => Promise<void>;
  fetchTagsByItemId: (id: string) => Promise<void>;

  // Single item state and loading
  currentItem: SingleItemResponse | null;
  isLoadingItem: boolean;
  errorItem: Error | null;
  totalScans: number;
  enhancedTags: any;
  fetchItemById: (id: string) => Promise<void>;
}

export function useItems(): UseItemsReturn {
  const [allItems, setAllItems] = useState<AllItemsResponse | []>([]);
  const [recentItems, setRecentItems] = useState<AllItemsResponse | []>([]);
  const [isLoadingAll, setIsLoadingAll] = useState<boolean>(false);
  const [errorAll, setErrorAll] = useState<Error | null>(null);

  const [currentItem, setCurrentItem] = useState<SingleItemResponse | null>(null);
  const [isLoadingItem, setIsLoadingItem] = useState<boolean>(false);
  const [errorItem, setErrorItem] = useState<Error | null>(null);
  const [enhancedTags, setEnhancedTags] = useState<Record<string, string>>({});
  const [totalScans, setTotalScans] = useState<number>(0);

  const fetchAllItems = async () => {
    setIsLoadingAll(true);
    setErrorAll(null);

    if (isLoadingAll) {
      return;
    }

    if (allItems && allItems.length > 0) {
      setIsLoadingAll(false);
      return;
    }

    try {
      const response = await fetch(API_URL + '/api/items/all', {
        method: 'GET',
        headers: getHeadersAuth()
      });
      if (!response.ok) {
        throw new Error('Failed to fetch all items');
      }
      const data = await response.json();
      setAllItems(data);
    } catch (error) {
      setErrorAll(error instanceof Error ? error : new Error('An error occurred'));
    } finally {
      setIsLoadingAll(false);
    }
  };

  // fetch recent new items
  const fetchRecentItems = async () => {
    setIsLoadingAll(true);
    setErrorAll(null);

    if (isLoadingAll) {
      return;
    }

    if (recentItems && recentItems.length > 0) {
      setIsLoadingAll(false);
      return;
    }

    try {
      const response = await fetch(API_URL + '/api/items/recent', {
        method: 'GET',
        headers: getHeadersAuth()
      });
      if (!response.ok) {
        throw new Error('Failed to fetch recent items');
      }
      const data = await response.json();
      setRecentItems(data);
    } catch (error) {
      setErrorAll(error instanceof Error ? error : new Error('An error occurred'));
    } finally {
      setIsLoadingAll(false);
    }
  };

  const fetchItemById = async (id: string) => {
    setIsLoadingItem(true);
    setErrorItem(null);

    try {
      const response = await fetch(API_URL + `/api/items/${id}`, {
        method: 'GET',
        headers: getHeadersAuth()
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch item with id ${id}`);
      }
      const data = await response.json();

      setCurrentItem(data);
    } catch (error) {
      setErrorItem(error instanceof Error ? error : new Error('An error occurred'));
    } finally {
      setIsLoadingItem(false);
    }
  };

  const fetchTagsByItemId = async (id: string) => {
    setIsLoadingItem(true);
    setErrorItem(null);

    try {
      const response = await fetch(API_URL + `/api/items/${id}/tags`, {
        method: 'GET',
        headers: getHeadersAuth()
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch tags for item with id ${id}`);
      }
      const data = await response.json();
      setTotalScans(data.totalScans);
      setEnhancedTags(data.tags || []);
      setIsLoadingItem(false);
    } catch (error) {
      setErrorItem(error instanceof Error ? error : new Error('An error occurred'));
    } finally {
      setIsLoadingItem(false);
    }
  };

  return {
    // All items
    allItems,
    recentItems,
    isLoadingAll,
    errorAll,
    fetchAllItems,
    fetchRecentItems,

    // Single item
    currentItem,
    isLoadingItem,
    errorItem,
    fetchItemById,
    fetchTagsByItemId,
    totalScans,
    enhancedTags
  };
}
