import { API_URL } from 'env';
import { useCallback, useMemo, useState } from 'react';
import { getHeadersAuth } from 'utils';
export interface IScanRecord {
  timestamp: number;
  tagId: string;
  tagUid: string;
  linkedItemId: string;
  linkedItemName: string;
  requestUrl: string;
  ipAddress: string;
  userAgent: string;
  ipCity?: string;
  ipCountry?: string;
  ipLocation?: string;
  ipLatitude?: string;
  ipLongitude?: string;
  scanStage?: string;
  scanType?: string;
  ctx: {
    [key: string]: string;
  };
}

interface TimeRange {
  start: number;
  end: number;
}

interface ScanRecordsFilter {
  timeRange?: TimeRange;
  tagId?: string;
  linkedItemId?: string;
  limit?: number;
  scanType?: string;
  scanStage?: string;
  ipCountry?: string;
}

interface ScanRecordStats {
  total: number;
  uniqueIps: number;
  uniqueLocations: number;
  avgScansPerDay: number;
  scansByCountry: Record<string, number>;
  scansByType: Record<string, number>;
  scansByStage: Record<string, number>;
}

interface ScanError {
  message: string;
  code?: number;
}

interface UseScanRecordsReturn {
  records: IScanRecord[];
  loading: boolean;
  error: ScanError | null;
  stats: ScanRecordStats;
  refreshRecords: () => Promise<void>;
  getRecordsByTagId: (tagId: string, timeRange?: TimeRange) => IScanRecord[];
  getRecordsByLinkedItem: (linkedItemId: string, timeRange?: TimeRange) => IScanRecord[];
  getLatestRecord: (tagId: string) => IScanRecord | undefined;
  getStats: (filter?: ScanRecordsFilter) => ScanRecordStats;
  getAllRecentScanRecords: () => Promise<void>;
}

const api = {
  // async fetchScanRecords(filter: ScanRecordsFilter): Promise<IScanRecord[]> {
  //   const params = new URLSearchParams();

  //   if (filter.timeRange) {
  //     params.append('start', filter.timeRange.start.toString());
  //     params.append('end', filter.timeRange.end.toString());
  //   }
  //   if (filter.tagId) params.append('tagId', filter.tagId);
  //   if (filter.linkedItemId) params.append('linkedItemId', filter.linkedItemId);
  //   if (filter.limit) params.append('limit', filter.limit.toString());
  //   if (filter.scanType) params.append('type', filter.scanType);
  //   if (filter.scanStage) params.append('stage', filter.scanStage);
  //   if (filter.ipCountry) params.append('country', filter.ipCountry);

  //   const response = await fetch(API_URL + `/api/scans?${params.toString()}`, {
  //     headers: getHeadersAuth()
  //   });
  //   if (!response.ok) throw new Error('Failed to fetch scan records');
  //   return response.json();
  // },

  async fetchAllRecentScanRecords(): Promise<IScanRecord[]> {
    const response = await fetch(API_URL + '/api/tags/scans/recent', {
      headers: getHeadersAuth()
    });
    if (!response.ok) throw new Error('Failed to fetch recent scan records');
    return response.json();
  }
};

const DEFAULT_TIME_RANGE = {
  start: Date.now() - 24 * 60 * 60 * 1000, // Last 24 hours
  end: Date.now()
};

export function useScanRecords(initialFilter: ScanRecordsFilter = { timeRange: DEFAULT_TIME_RANGE }, autoLoad = true): UseScanRecordsReturn {
  const [records, setRecords] = useState<IScanRecord[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ScanError | null>(null);
  const [currentFilter, setCurrentFilter] = useState<ScanRecordsFilter>(initialFilter);

  // Filter helpers
  const filterByTimeRange = useCallback((records: IScanRecord[], timeRange?: TimeRange) => {
    if (!timeRange) return records;
    return records.filter((record) => record.timestamp >= timeRange.start && record.timestamp <= timeRange.end);
  }, []);

  // Get records by tag ID
  const getRecordsByTagId = useCallback(
    (tagId: string, timeRange: TimeRange = DEFAULT_TIME_RANGE) => {
      return filterByTimeRange(
        records.filter((record) => record.tagId === tagId),
        timeRange
      );
    },
    [records, filterByTimeRange]
  );

  // Get records by linked item ID
  const getRecordsByLinkedItem = useCallback(
    (linkedItemId: string, timeRange: TimeRange = DEFAULT_TIME_RANGE) => {
      return filterByTimeRange(
        records.filter((record) => record.ctx.linkedItemId === linkedItemId),
        timeRange
      );
    },
    [records, filterByTimeRange]
  );

  // Get latest record for a tag
  const getLatestRecord = useCallback(
    (tagId: string) => {
      return records.filter((record) => record.tagId === tagId).sort((a, b) => b.timestamp - a.timestamp)[0];
    },
    [records]
  );

  // Calculate statistics
  const getStats = useCallback(
    (filter?: ScanRecordsFilter): ScanRecordStats => {
      let filteredRecords = [...records];

      if (filter?.timeRange) {
        filteredRecords = filterByTimeRange(filteredRecords, filter.timeRange);
      }
      if (filter?.tagId) {
        filteredRecords = filteredRecords.filter((r) => r.tagId === filter.tagId);
      }
      if (filter?.linkedItemId) {
        filteredRecords = filteredRecords.filter((r) => r.ctx.linkedItemId === filter.linkedItemId);
      }

      const uniqueIps = new Set(filteredRecords.map((r) => r.ipAddress)).size;
      const uniqueLocations = new Set(filteredRecords.map((r) => r.ipLocation)).size;

      // Calculate scans per day
      const timeSpanDays = filter?.timeRange ? (filter.timeRange.end - filter.timeRange.start) / (24 * 60 * 60 * 1000) : 1;
      const avgScansPerDay = filteredRecords.length / timeSpanDays;

      // Group by country
      const scansByCountry = filteredRecords.reduce(
        (acc, record) => {
          const country = record.ipCountry || 'Unknown';
          acc[country] = (acc[country] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      );

      // Group by type
      const scansByType = filteredRecords.reduce(
        (acc, record) => {
          const type = record.scanType || 'Unknown';
          acc[type] = (acc[type] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      );

      // Group by stage
      const scansByStage = filteredRecords.reduce(
        (acc, record) => {
          const stage = record.scanStage || 'Unknown';
          acc[stage] = (acc[stage] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      );

      return {
        total: filteredRecords.length,
        uniqueIps,
        uniqueLocations,
        avgScansPerDay,
        scansByCountry,
        scansByType,
        scansByStage
      };
    },
    [records, filterByTimeRange]
  );

  // Memoized stats for current filter
  const currentStats = useMemo(() => getStats(currentFilter), [getStats, currentFilter]);

  const getAllRecentScanRecords = async () => {
    setLoading(true);
    setError(null);

    try {
      const fetchedRecords = await api.fetchAllRecentScanRecords();
      const filtered = fetchedRecords.filter((record) => record.scan_stage == 1);
      setRecords(filtered);
    } catch (err) {
      setError({
        message: err instanceof Error ? err.message : 'Failed to fetch recent scan records',
        code: 500
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    records,
    loading,
    error,
    stats: currentStats,
    refreshRecords: () => refreshRecords(),
    getRecordsByTagId,
    getRecordsByLinkedItem,
    getLatestRecord,
    getStats,
    getAllRecentScanRecords
  };
}
