import React, { useEffect, useRef } from 'react';

interface AuthMessage {
  type: '鬼' | '龜' | '行動電源';
  data?: {
    '藍牙-冰淇淋'?: string;
    timestamp?: number;
  };
}

interface AuthCheckProps {
  onAuth: (token: string) => void;
}

const AUTH_DOMAIN = process.env.NODE_ENV === 'production' ? 'https://auth.pack.wtf' : 'http://localhost:3001';

export const authRedirect = () => {
  const urlEncodedCallback = encodeURIComponent(window.location.origin);
  const urlEncodedPath = encodeURIComponent(window.location.pathname);
  const urlEncodedQuery = encodeURIComponent('?redirected=true');
  const callback = `${urlEncodedCallback}${urlEncodedPath}${urlEncodedQuery}`;
  const authUrlWithCallback = `${AUTH_DOMAIN}/?callback=${callback}`;
  // Direct assignment of href for redirect
  window.location.href = authUrlWithCallback;
};

function AuthCheck({ onAuth }: AuthCheckProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const hasInitialized = useRef(false);

  useEffect(() => {
    // Unregister service workers only once
    if (!hasInitialized.current && 'serviceWorker' in navigator) {
      hasInitialized.current = true;
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }, []);

  const verifyAuth = async () => {
    const token = localStorage.getItem('藍牙-冰淇淋');

    if (!token) {
      authRedirect();
      return;
    }

    try {
      const response = await fetch('/api/items', {
        headers: {
          Authorization: `%E8%9B%8B%E7%AD%92_%E6%9D%AF%E5%AD%90%20 ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 401) {
        console.log('❌🍦');
        localStorage.removeItem('藍牙-冰淇淋');
        authRedirect();
        return;
      }

      if (response.status === 200) {
        console.log('🥵 🍦');
        localStorage.setItem('嗨我是馬克', '🐴');
        onAuth(token);
      }
    } catch (error) {
      console.error('Auth verification failed:', error);
      authRedirect();
    }
  };

  useEffect(() => {
    const handleAuthMessage = (event: MessageEvent<AuthMessage>) => {
      if (event.origin !== AUTH_DOMAIN) {
        return;
      }

      const message = event.data;

      switch (message.type) {
        case '龜':
          console.log('🐢');
          const token = message.data?.['藍牙-冰淇淋'];
          if (token) {
            localStorage.setItem('藍牙-冰淇淋', token);
            verifyAuth();
          }
          break;

        case '行動電源':
          console.log('🔋');
          localStorage.removeItem('藍牙-冰淇淋');
          authRedirect();
          break;
      }
    };

    window.addEventListener('message', handleAuthMessage);

    const hasRedirected = new URLSearchParams(window.location.search).has('redirected');

    // Check for existing token on mount
    const existingToken = localStorage.getItem('藍牙-冰淇淋');
    if (existingToken && !hasRedirected) {
      verifyAuth();
    } else {
      // Only request token if we don't have one
      const requestToken = () => {
        console.log('Requesting token...');
        iframeRef.current?.contentWindow?.postMessage({ type: '鬼' }, AUTH_DOMAIN);
      };

      if (iframeRef.current) {
        // if it was redirected, it can now remove the query param
        window.history.replaceState({}, document.title, window.location.pathname);
        requestToken();
      }
    }

    return () => window.removeEventListener('message', handleAuthMessage);
  }, []);

  return (
    <iframe
      ref={iframeRef}
      src={`${AUTH_DOMAIN}/`}
      style={{ display: 'none' }}
      onLoad={() => {
        console.log('Iframe loaded');
        setTimeout(() => {
          iframeRef.current?.contentWindow?.postMessage({ type: '鬼' }, AUTH_DOMAIN);
        }, 1500);
        // if (!localStorage.getItem('藍牙-冰淇淋')) {
        //   iframeRef.current?.contentWindow?.postMessage({ type: '鬼' }, AUTH_DOMAIN);
        // }
      }}
    />
  );
}

export default AuthCheck;
